@mixin onHover($background,$color){
  @include transition(all 320ms ease);
  &:hover{
    background-color: $background;
    color: $color;
  }

}
@mixin onHoverWithBorder($background,$border,$color){
  @include transition(all 320ms ease);
  &:hover{
    background-color: $background;
    color: $color;
    border: $border;
  }

}