// Colors
$io-new-flag-color: #3784d0;
$io-blue-strong: #3785D1;
$io-blue-light:  #235264;
$io-blue-medium: #174050;
$io-blue-dark:   #11323F;
$io-green:       #5D992C;
$io-yellow:      #FF9000;
$io-red:         #D52C28;
$io-white:       #FFFFFF;
$io-grey-light:  #EBEBEB;
$io-grey-medium: #D8D8D8;
$io-grey-dark:   #575757;


// Fonts
$font-default: 'Open Sans', sans-serif !default;
$font-heading: 'Roboto Condensed', sans-serif;
$font-icon: 'FontAwesome';
$fa-css-prefix: icon;
$fa-font-path: "../fonts/font-awesome";

// Other custom stuff

$io-pillar-space: 54px;
$column-padding: 37px;
$bannerViewWidth: 40.8vw;

$border-radius-base: 5px;
$border-radius-large: 5px;
$border-radius-small: 5px;

$btn-danger-color:  $io-white;
$btn-danger-bg:  $io-red;
$btn-danger-border: $io-red;
$grid-gutter-width: 10px;

$container-tablet: (780px) !default;
$container-desktop: (1000px) !default;
$container-large-desktop: (1200px) !default;

//Forms
$input-border-radius: 0;
$input-color: $io-grey-dark;
$input-font-size: 12px;

//Buttons
$btn-default-color: $io-white;
$btn-default-bg: $io-blue-light;
$btn-default-border: $io-blue-light;

$left-column-width: 20%;

$top_column_gutter: 4px;

// Prestashop styles
$ps-border-color: $io-grey-medium;

// @TODO Override top navbar collapse point

$font-size-base: 14px; // Default is 14px
$font-size-h1: floor(($font-size-base * 2.1)); // ~29px (default: 36px)
$font-size-h2: floor(($font-size-base * 1.8)); // ~25px (default: 30px)
$font-size-h3: ceil(($font-size-base * 1.6)); // ~23px (default: 24px)
$font-size-h4: ceil(($font-size-base * 1.4)); // ~20px (default: 18px)
$font-size-h5: ceil(($font-size-base * 1.2)); // ~17px (default: 14px)
$font-size-h6: $font-size-base; // ~14px (default: 12px)

// @TODO Overwrite theses variables to customize the colors of Bootstrap:

// @see themes/community-theme-16/sass/vendor/bootstrap/bootstrap/_variables.scss
$brand-primary: $io-blue-light !default; // #337ab7
// $brand-success:  #5cb85c;
// $brand-info:     #5bc0de;
// $brand-warning:  #f0ad4e;
// $brand-danger:   #d9534f;

// @see themes/community-theme-16/sass/vendor/bootstrap/bootstrap/_variables.scss
// $state-success-bg: #dff0d8;
// $state-info-bg:    #d9edf7;
// $state-warning-bg: #fcf8e3;
// $state-danger-bg:  #f2dede;

// @see themes/community-theme-16/sass/vendor/bootstrap/bootstrap/mixins/_forms.scss
// Change the glow color of .form-control when in focus
$input-border-focus: $brand-primary;

// Custom mixins + bourbon
@import "bourbon";
@import "vendor_mixins";