@import "../../../../autoload/compatibility";

.inside_border {
  border: 1px solid $io-white;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.column_icon_left {
  width: 55px;
  svg {
    width: 50px;
    height: 35px;
    fill: $io-grey-dark;
    * {
      fill: $io-grey-dark;
    }
    *[class^="st"] {
      display: block;
    }
  }

  @media (max-width: $screen-sm-max) {
    width: 25%;
    text-align: center;
    svg {
      width: 40px;
      height: 30px;
    }
  }

  @media (max-width: $screen-xs-max) {
    display: none;
  }

}

.pillar_title {
  color: $io-white;
  font-weight: bold;
  width: 100%;
  height: 88px;
  text-align: center;
  vertical-align: middle;

  &.inactive {
    background: $io-grey-light;
    color: $io-grey-dark;
    .pillar_icon svg,
    .pillar_icon svg * {
      fill: $io-grey-dark;
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    text-align: left;
  }

  a {
    color: inherit;
    display: table;
    font-family: $font-heading;
    font-size: 20px;
    font-weight: 200;
    height: 100%;
    letter-spacing: 0;
    text-decoration: none;
    padding: 0 5px;
    width: 100%;

    @media (max-width: $screen-xs-max) {
      padding: 0;
      font-size: 22px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      font-size: 16px;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
    }
  }

  @media (max-width: $screen-xs-max) {
    text-align: left;
    .pillar_icon {
      width: 100%;

    }
    &:not(.index) {
      //width: 20%;
      height: 65px;
      a {
        height: 65px;
        padding: 0;
        text-align: center;
        display: table;
      }

      .pillar_icon {
        margin-right: 0;
        /*padding: 0 25%;*/
        display: table-cell;
      }

      .category_title:last-child {
        display: table-cell;
        line-height: 1.1em;
        padding-right: 50px;

      }

    }
    .category_title {
      display: table-cell;
    }
  }

  .pillar_icon {
    display: table-cell;
    vertical-align: middle;
    padding: 0 5px;
    width: 25%;
    height: 40px;
    svg *[class^='st'] {
      display: block;
    }

    @media (max-width: $screen-xs-min) {
      display: inline-block;
      padding: 0;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      display: none;
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      width: 40px;
    }

    svg,
    svg * {
      height: 40px;
      width: 100%;
      display: block;
      fill: $io-white;
      @include transition(all 350ms ease);
    }
  }

  span {
    display: table-cell;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;

    @media (max-width: $screen-xs-max) {
      display: table-cell;
      max-width: calc(100% - 88px);
      text-align: left;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: inline;
    }
  }

  &.index {
    @media (max-width: $screen-xs-max) {
      text-align: left;

      .pillar_icon {
        display: table-cell;
        margin: 0 15px;
        width: 20%;
      }

    }
  }
}

.pillar_container {
  display: block;
  white-space: nowrap;

  > * {
    position: relative;
  }
}

.pillar_image {
  height: auto;
  width: 100%;
}

.pillar_link {
  float: left;
  padding: 0 2px;

}

body#index {
  @media(max-width: $screen-xs-max) {
    .pillar_link {
      width: 100% !important;
    }
  }
}

.pillar_cell {
  background-color: $io-grey-light;
  margin-bottom: 4px;
  min-height: 30px;
  position: relative;
  @include transition(all 350ms ease);

  &:hover:not(:last-child) {
    background-color: $io-white;
  }

  @media (max-width: $screen-xs-max) {
    display: none;

    &.pillar_title {
      display: block;
    }
  }

  &.one-line {
    > a {
      bottom: 0;
      color: $io-grey-dark;
      display: block;
      float: left;
      overflow: hidden;
      padding: 5px 28px;
      position: absolute;
      text-align: center;
      text-overflow: ellipsis;
      top: 0;
      white-space: nowrap;
      width: 100%;
      @include transition(350ms all ease);

      &:hover {
        color: $io-blue-strong;
      }
    }
  }

  &.last-cell {
    height: 75px;

    .btn {
      border: 1px solid transparent;
      color: $io-white;
      left: 50%;
      outline: 0;
      position: absolute;
      top: 50%;
      width: 130px;
      @include transform(translate(-50%, -50%));
      @include transition(all 350ms ease);
    }
  }
}

.inosnabasic_more_details {
  height: 130px;
}

.inosnabasic_more_details_btn {
  border-radius: 5px;
  border: none;
  bottom: 15px;
  color: white;
  height: 40px;
  left: 50%;
  position: absolute;
  width: 60%;
  @include transform(translateX(-50%));
}

.mainpage-bigbanner {
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 240px;
  padding: 20px;
}

.pillar-wrapper-main {
  .pillar_column {
    position: relative;
    padding-left: 2px;
    padding-right: 2px;
  }
}

.pillar-wrapper-pillarpage {

  .pillar_column {
    float: left;
    padding: 4px;

    &:first-child {
      width: $left_column-width;
    }
  }

  @media(min-width: $screen-sm-min){
    .collapse,
    .collapse.in{
      display:block !important;
    }
  }

  position: inherit;
  top: -240px;
  &:after {
    clear: none;
  }

  .pillar_link{
    @media (max-width: $screen-xs-max) {
      width: 100% !important;
    }
  }
}

.inosnabasic-categories-left-column {
  width: 100%;

  .category {
    height: 50px;
    margin-bottom: 4px;
    width: 100%;
    display: inline-table;

    a {
      background-color: $io-grey-light;
      color: $io-grey-dark;
      display: inline-table;
      float: left;
      height: 100%;
      position: relative;
      text-decoration: none;
      width: 100%;
      @include onHover($io-white, $io-blue-medium);

      &.active {
        background: $io-white;
        color: $io-blue-medium;
      }

      img {
        margin-left: 10px;
        position: absolute;
        top: 25%;

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          display: none;
        }
      }
      span {
        display: table-cell;
        vertical-align: middle;
      }
      .category_title {
        display: table-cell;
        padding: 5px;
        width: 75%;
        text-align: left;
        vertical-align: middle;
        -moz-hyphens: auto;
        -o-hyphens: auto;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          font-size: 12px;
        }
      }
    }
  }

  :last-child {
    margin-bottom: 0;
  }
}

#newsletter-left {
  background-color: $io-grey-light;

  .nl-header {
    background-color: $io-blue-light;
    color: $io-white;
    font-family: $font-heading;
    font-weight: 200;
    letter-spacing: 1px;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
  }

  .nl-content {
    padding: 20px;

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      font-size: 12px;
      padding: 5px;
    }
  }

  input[type="text"],
  input[type="email"] {
    margin: 10px 0 10px;
  }

  p {
    margin: 0 0 10px;
  }

  .checkbox {
    padding: 0 0 10px;
  }

  button {
    margin-top: 20px;
  }
}

.linkInfoBanner {
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100%;

  img {
    width: 100%;
    @include transition(all 200ms ease);

    &:hover {
      opacity: 0.75;
    }
  }
}

#discover {
  padding: 0;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAKElEQVQYV2NkQANnz579z4gsBhIwNjZmhAvCBECKwILIAmBBdAGQIADd3RNsfP2ELAAAAABJRU5ErkJggg==");
  background-color: $io-grey-light;

  @media (max-width: $screen-xs-max) {
    padding: 10px 0;
  }

  .row > h2 {
    margin-bottom: 35px;
    color: $io-grey-dark;
  }

  .list {
    width: 100%;
    display: block;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;

    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;

    padding: 0 37px;
    @media (max-width: $screen-xs-max) {
      //display: block;
    }
  }

  .item {
    position: relative;
    background: $io-white;
    box-sizing: border-box;

    -webkit-flex: 1 1 28%;
    -ms-flex: 1 1 28%;
    flex: 1 1 28%;

    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;

    margin: 1%;
    padding: 10px;
    margin-bottom: 15px !important;

    @media (min-width: $screen-md-min) {
      &:nth-child(3n+1) {
        margin-left: 0;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      -webkit-flex: 1 1 45%;
      -ms-flex: 1 1 45%;
      flex: 1 1 45%;

      &:nth-child(2n+1) {
        margin-left: 0;
      }
      &:nth-child(2n+2) {
        margin-right: 0;
      }

      &:nth-child(2n+1):last-child {
        margin-right: 0%;
        margin-left: 0%;
      }
    }

    @media (max-width: $screen-xs-max) {
      -webkit-flex: 1 1 98%;
      -ms-flex: 1 1 98%;
      flex: 1 1 98%;
      margin-left: 1% !important;
      margin-right: 1% !important;

    }

    .img-container {
      position: relative;
      width: 100%;
      height: 120px;
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-height: 120px;
        @include transform(translate(-50%, -50%));
      }
    }

    .text-container {
      padding: 10px 27px;

      span {
        margin-bottom: 35px;
        display: block;
      }
    }

    .mobile-button {
      a {
        color: $io-grey-dark;
        display: inline-block;
        font-family: $font-heading;
        font-size: 18px;
        padding: 12px;
        position: inherit;
        text-decoration: none;
        width: 100%;

        &:hover,
        &:active {
          color: $io-blue-strong;
        }

        &:after {
          background: transparent url(../../img/arrow.svg) center no-repeat;
          background-size: 30px;
          content: '';
          display: block;
          float: right;
          height: 20px;
          width: 20px;
          vertical-align: middle;
          -webkit-transform: rotate(90deg) rotateX(180deg);
          -ms-transform: rotate(90deg) rotateX(180deg); // IE9 only
          -o-transform: rotate(90deg) rotateX(180deg);
          transform: rotate(90deg) rotateX(180deg);
          @include transition(350ms ease);
        }
        i {
          margin-top: 2px;
        }
      }
    }

    a {
      position: absolute;
      bottom: 15px;
      left: 30px;
      color: $io-blue-strong;

      i {
        font-size: 20px;
        vertical-align: middle;
        padding-left: 3px;
      }
    }
  }

}

#about {
  background-color: $io-white;
  padding-top: 50px;

  .container {
    padding-left: 37px;
    padding-right: 37px;
    position: relative;

    .text-container {
      text-align: left;
      $spacing: 10px;

      h2 {
        margin: 0;
        padding: 0 0 $spacing;
      }

      p {
        margin: 0;
        padding: $spacing 0;
      }

      a {
        margin: $spacing 0;
      }

      @media (max-width: $screen-xs-max) {
        & > div {
          text-align: center;
        }
      }
    }

    .image-container {
      &.ghost {
        position: static;
        opacity: 0;
      }
      @media (min-width: $screen-md-min) {
        bottom: 0;
        position: absolute;
        right: 0;
        &.ghost {
          position: static;
          opacity: 0;
        }
      }

      @media (max-width: $screen-sm-max) {
        &.ghost {
          display: none;
        }
      }

      img {
        max-width: 100%;

        @media (min-width: $screen-md-min) {
          @include img-responsive();
        }
      }
    }
  }
}

#inosnabasic_hookhome_title {
  background-color: $io-grey-light;
  font-family: $font-heading;
  height: 50px;
  position: relative;
  span {
    position: absolute;
    color: $io-grey-dark;
    display: block;
    font-size: $font-size-h2;
    padding: 20px 0;
    font-weight: 200;
    letter-spacing: 2.2px;
    margin: 0;
    width: 100%;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);

    @media (max-width: 449px) {
      font-size: 18px;
    }
  }
}

div.medium-banner {

  &.map {
    height: 283px;
    overflow: hidden;
    position: static;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

#map {
  height: 100%;
  width: 100%;
}

#mapAjax,
#mapError {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  color: $io-blue-dark;

  &.load {
    background-color: rgba(20, 20, 20, 0.65);
    color: #000;
  }

  div.icon,
  div.error {
    top: 50%;
    left: 50%;
    position: absolute;
    @include translate(-50%, -50%);
    z-index: 2;
    .icon-wrapper {
      padding: 10px
    }
    .text-wrapper {
      h4 {
        font-weight: 800;
      }
    }
  }

}

$outfield_width: 30vw;
$io-nn-width: 300px;
$io-nn-height: 300px;
$io-nn-border-size: 1px;
#newsletterBox {
  position: fixed;
  bottom: 60px;
  right: 0;
  z-index: 201;
  @media(max-width: $screen-xs-max){
    width: 100% !important;
    #newsletterBoxOuter,.bg{
      width: 100% !important;
    }
  }
  #newsletterBoxOuter {
    position: relative;
    top: -10px;
    left: -10px;
    z-index: -2;
    background-color: transparent;
    &.open {
      @include translate(0, -100%);
    }
  }

  .newsletter-box {
    cursor: pointer;
    @include transition(all 350ms);
    border: $io-nn-border-size solid #fff;
    position: absolute;
    top: 0;
    right: 65px;
    width: $io-nn-width;
    height: $io-nn-height;
    @media(max-width: $screen-xs-max){
      width: 90% !important;
      left: 5%;
    }
    z-index: 202;
    //color: #fff;
    @include box-shadow(0px 0px 20px -4px rgba(0, 0, 0, 0.55));
    &.open {
      cursor: default;
      .bg {
        opacity: 0.98;
        .inner{
          background-color: #fff;
        }
      }
      @include translate(0, -110%);

    }
    .arrow{
      @include transition(all 350ms);
    }
    .info,
    .title,
    .content,
    .footer{
      padding: 0 35px;
      //color: #fff;
    }
    .info,
    .arrow{
      color: #fff;
    }
    .footer{
      padding-top: 20px;
      position: absolute;
      bottom: 30px;

      .checkbox{
        .help-block{
          display: none !important;
        }
      }
    }
    .bg {
      @include transition(all 350ms);
      width: ($io-nn-width - $io-nn-border-size*2);
      height: ($io-nn-height - $io-nn-border-size*2);
      position: absolute;
      background-color: $io-blue-medium;
      opacity: 0.85;
      padding: 25px 15px 15px 15px;
      z-index: -1;
      .inner{
        @include transition(all 350ms);
        background: transparent;
        width:100%;
        height: 100%;
      }
    }
  }
  z-index: 200;

}
#journey{
  position: relative;

}
#route-btn{
  position: absolute;
  top: 20px;
  right: 0;
  @media(max-width: $screen-xs-max){
    right: 20px;
  }

}

#mobile-filter-overlay{
  &.hidden{
    height: 0;
    display: block !important;
  }
  height: 100%;
  z-index: 300 !important;
  @include transition(all 350ms ease);
}

#filterBox{

  color: #fff;
  background-color: $io-blue-light;
  h1,h2,h3,h4,h5{
    color: #fff;
  }
  .btn,
  .btn-show-advanced{
    color:#fff !important;
    &:hover,
    &:visited,
    &:focus,
    &:active{
      color: #fff;
    }
  }
  &:hover{
    color: #fff;
  }
  text-align: left;
  margin: 5px 20px 15px;
  @include  transition(all 350 ease);
  border: 1px solid $io-grey-light;
  border-radius: 0;

  select{
    cursor: pointer;
  }

  .btn-filter{
    position: relative;
    cursor: pointer;
    .icon{
      font-size: 1.4em;
      margin-right: 20px;
    }
    .icon,span{
      vertical-align: middle;
    }
    &.open{
      cursor: default;
    }

  }
  .btn-filter,
  div.block_content{
    width: 100%;
    padding: 10px 20px;
  }
  .btn-close{

    background-color: transparent;
    border: none;
    border-radius: 0;
    width: 25px;
    height: 25px;
    position: absolute;
    text-align: center;
    padding: 2px 0;
    right: 10px;
    top: 10px;
  }
  .filterContent{

  }
}

div.flex_filler{
  flex-grow: 1 !important;
}

#pillar_nav{
  position: absolute;
  right: 0;
  width: 60px;
  text-align: center;
  height: 65px;
  cursor: pointer;
  @media(min-width: $screen-sm-min){
    display: none;
  }
  i{
    @include transition(350ms all);
    @include scale(1);
    margin-top: 24px;
  }
  &:hover {
    i{
      @include scale(1.5);
    }
  }



}